import revive_payload_client_4sVQNw7RlN from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/kids.fomin-clinic.ru/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/kids.fomin-clinic.ru/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_yqYgmG0R8w from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt-lazy-hydrate/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/kids.fomin-clinic.ru/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_Z9R5b5Ynf8 from "/var/www/kids.fomin-clinic.ru/build/plugins/error.js";
import gtm_client_KEcT5dOoKs from "/var/www/kids.fomin-clinic.ru/build/plugins/gtm.client.js";
import maska_client_S2Wtj77gAt from "/var/www/kids.fomin-clinic.ru/build/plugins/maska.client.js";
import vcalendar_client_6gEtUomjNC from "/var/www/kids.fomin-clinic.ru/build/plugins/vcalendar.client.js";
import ymapPlugin_client_Iny3q2BR5Y from "/var/www/kids.fomin-clinic.ru/build/plugins/ymapPlugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_yqYgmG0R8w,
  chunk_reload_client_UciE0i6zes,
  error_Z9R5b5Ynf8,
  gtm_client_KEcT5dOoKs,
  maska_client_S2Wtj77gAt,
  vcalendar_client_6gEtUomjNC,
  ymapPlugin_client_Iny3q2BR5Y
]