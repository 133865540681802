<template>
    <div>
        <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#72E0D8 0%,#E5C2CC 50%,#bfafd4 100%)"/>
        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
    </div>
</template>
<script setup lang="ts">
</script>
