export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path !== from.path && process.client) {
    const ver = useCookie("ver");
    const build_ver = await fetch("/ver.info").then((response) =>
      response.text()
    );
    if (ver.value < build_ver) {
      document.location = to.fullPath;
    }

    window.addEventListener("error", (event) => {
      console.log(event);
    });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }
});
