// import { defineNuxtPlugin } from "nuxt/app";
import { useUi } from "~/store/ui";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context, info) => {
    console.log(error);
    error += "";
    switch (error) {
      // case "TypeError: e.focus is not a function":
      // case "TypeError: Cannot read properties of undefined (reading 'map')":
      // case "TypeError: Cannot read properties of undefined (reading '0')":
      // case "TypeError: Cannot read properties of undefined (reading 'geoObjects')":
      //   clearError();
      //   break;
      default:
        // const storeUi = useUi();
        // storeUi.openError();
    }

    // setTimeout(() => {
    //   console.log(info);
    //   console.log("" + error);
    //   console.log();
    //   console.log(typeof error);
    //   console.log(Object.keys(error));
    //   console.log(context);
    //   const slotsUi = useUi();
    //
    // }, 1000);

    // if (confirm("Произошла ошибка. Мы ее уже исправляем.")) {
    //   // clearError();
    //   location.reload();
    // }
    clearError();
  };
});
