import YmapPlugin from "vue-yandex-maps";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(YmapPlugin, {
        apiKey: '4f9fce41-93d6-44f6-be5a-a8145eb23ca7', // Индивидуальный ключ API
        lang: 'ru_RU', // Используемый язык
        coordorder: 'latlong', // Порядок задания географических координат
        debug: false, // Режим отладки
        version: '2.1' // Версия Я.Карт
    });
});
