import { default as _91region_93hEw6RwwThOMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/__contacts/[region].vue?macro=true";
import { default as allU7a3E3dXxWMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/__contacts/all.vue?macro=true";
import { default as indexgHlqmJRXIcMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/__contacts/index.vue?macro=true";
import { default as indexbp2tu7FzWhMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/about/index.vue?macro=true";
import { default as ai6CbS9brzaTMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/ai.vue?macro=true";
import { default as debugfvn4oCoZwMMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/app/debug.vue?macro=true";
import { default as indexC2E61TXh8cMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/app/index.vue?macro=true";
import { default as _91blog_93a39Akn13z5Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/blog/[blog].vue?macro=true";
import { default as indexzslPBZMyEmMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/blog/index.vue?macro=true";
import { default as _91clinic_939fWj3kZ87zMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/contacts/[clinic].vue?macro=true";
import { default as index8xI9awk4JRMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/contacts/index.vue?macro=true";
import { default as developP6Zi04ZrF2Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/develop.vue?macro=true";
import { default as _91doc_93BqaoZvSyC3Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/docs/[doc].vue?macro=true";
import { default as indexfTa3gWXVnhMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/docs/index.vue?macro=true";
import { default as embefIVaXGQO5Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/emb.vue?macro=true";
import { default as index6y2GtAglu8Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/index.vue?macro=true";
import { default as depositgJIayPIbpcMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/info/deposit.vue?macro=true";
import { default as documentsZz6L0itIdlMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/info/documents.vue?macro=true";
import { default as preparationpQPn5hAlp0Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/info/preparation.vue?macro=true";
import { default as mobilemV9lO8pCgSMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/mobile.vue?macro=true";
import { default as pricePhyHiQVNfxMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/price.vue?macro=true";
import { default as complexc03QapDG40Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/programs/complex.vue?macro=true";
import { default as customkPEAGT1On6Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/programs/custom.vue?macro=true";
import { default as roadzhibLiDAWlMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/road.vue?macro=true";
import { default as secretfsfWCg8n5UMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/secret.vue?macro=true";
import { default as _91service_93FPbDvZNgRDMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/services/[service].vue?macro=true";
import { default as index38DuFIB0UaMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/services/index.vue?macro=true";
import { default as _91vacc_93IpdYp4yEt3Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/vacc/[vacc].vue?macro=true";
import { default as advantage8Jq10KVO69Meta } from "/var/www/kids.fomin-clinic.ru/build/pages/vacc/advantage.vue?macro=true";
import { default as indexSP4hXWAc3eMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/vacc/index.vue?macro=true";
import { default as individualQpWI3YKn6oMeta } from "/var/www/kids.fomin-clinic.ru/build/pages/vacc/individual.vue?macro=true";
export default [
  {
    name: "__contacts-region",
    path: "/__contacts/:region()",
    meta: _91region_93hEw6RwwThOMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/__contacts/[region].vue").then(m => m.default || m)
  },
  {
    name: "__contacts-all",
    path: "/__contacts/all",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/__contacts/all.vue").then(m => m.default || m)
  },
  {
    name: "__contacts",
    path: "/__contacts",
    meta: indexgHlqmJRXIcMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/__contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    meta: indexbp2tu7FzWhMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "ai",
    path: "/ai",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/ai.vue").then(m => m.default || m)
  },
  {
    name: "app-debug",
    path: "/app/debug",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/app/debug.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-blog",
    path: "/blog/:blog()",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts-clinic",
    path: "/contacts/:clinic()",
    meta: _91clinic_939fWj3kZ87zMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/contacts/[clinic].vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: index8xI9awk4JRMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "develop",
    path: "/develop",
    meta: developP6Zi04ZrF2Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/develop.vue").then(m => m.default || m)
  },
  {
    name: "docs-doc",
    path: "/docs/:doc()",
    meta: _91doc_93BqaoZvSyC3Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/docs/[doc].vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    meta: indexfTa3gWXVnhMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "emb",
    path: "/emb",
    meta: embefIVaXGQO5Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/emb.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index6y2GtAglu8Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-deposit",
    path: "/info/deposit",
    meta: depositgJIayPIbpcMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/info/deposit.vue").then(m => m.default || m)
  },
  {
    name: "info-documents",
    path: "/info/documents",
    meta: documentsZz6L0itIdlMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/info/documents.vue").then(m => m.default || m)
  },
  {
    name: "info-preparation",
    path: "/info/preparation",
    meta: preparationpQPn5hAlp0Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/info/preparation.vue").then(m => m.default || m)
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: mobilemV9lO8pCgSMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: "price",
    path: "/price",
    meta: pricePhyHiQVNfxMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/price.vue").then(m => m.default || m)
  },
  {
    name: "programs-complex",
    path: "/programs/complex",
    meta: complexc03QapDG40Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/programs/complex.vue").then(m => m.default || m)
  },
  {
    name: "programs-custom",
    path: "/programs/custom",
    meta: customkPEAGT1On6Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/programs/custom.vue").then(m => m.default || m)
  },
  {
    name: "road",
    path: "/road",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/road.vue").then(m => m.default || m)
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: "services-service",
    path: "/services/:service()",
    meta: _91service_93FPbDvZNgRDMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/services/[service].vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    meta: index38DuFIB0UaMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "vacc-vacc",
    path: "/vacc/:vacc()",
    meta: _91vacc_93IpdYp4yEt3Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/vacc/[vacc].vue").then(m => m.default || m)
  },
  {
    name: "vacc-advantage",
    path: "/vacc/advantage",
    meta: advantage8Jq10KVO69Meta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/vacc/advantage.vue").then(m => m.default || m)
  },
  {
    name: "vacc",
    path: "/vacc",
    meta: indexSP4hXWAc3eMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/vacc/index.vue").then(m => m.default || m)
  },
  {
    name: "vacc-individual",
    path: "/vacc/individual",
    meta: individualQpWI3YKn6oMeta || {},
    component: () => import("/var/www/kids.fomin-clinic.ru/build/pages/vacc/individual.vue").then(m => m.default || m)
  }
]